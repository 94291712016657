import { Injectable } from '@angular/core'
import { BehaviorSubject, of, combineLatest } from 'rxjs'
import { UserNameService } from './user-name.service'
import { ApiService } from './api.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Settings, SettingsService } from './settings.service';
// TODO: SHOULD BE REVISED FOR CERTAIN USERS

export interface UserPermission {
  management?: boolean
  approvals?: boolean
  stagingApprovals?: boolean
  uatApprovals?: boolean
  workspaces?: boolean
  testManager?: boolean
  developer?: boolean
  addRules?: boolean
  editTemplates?: boolean
  editTargetSystem?: boolean
  editSupportedStatus?: boolean
  restartSmartDx?: boolean
  rebaselineTests?: boolean
  deleteRule?: boolean
  editStaticData?: boolean
  editMappings?: boolean
  createConfig?: boolean
  canSaveNegativeTests?: boolean
  canApproveRule?: boolean
  readonly?: boolean
  regxchangeCSPRODSupport?: boolean
  regxchangeCSUATSupport?: boolean
  editRuleSets?: boolean
  canRollbackCommit?: boolean;
  // Below permissions are high level permissions for the left side menu in Storm UI
  playgroundMenu?: boolean
  dashboardMenu?: boolean
  configMenu?: boolean
  inspectMenu?: boolean
  generateMenu?: boolean
  publishMenu?: boolean
  testsMenu?: boolean
  toolsMenu?: boolean
  manualDraftingMenu?: boolean
  adminMenu?: boolean
  preprocessorLogic?: boolean,
  wallStreetDocsPostRequest?: boolean
}

const userPermissions: { [key: string]: UserPermission } = {
  'mark.aldous': {
    management: true,
    approvals: true,
    testManager: true,
    canApproveRule: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'ricardo.cruz': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editTemplates: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canApproveRule: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'kyle.reed': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editTemplates: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canApproveRule: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'annette.fischer': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editTemplates: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canApproveRule: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'henry.holland': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editTemplates: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canApproveRule: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'gareth.payne': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    addRules: true,
    editTemplates: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    editTargetSystem: true,
    regxchangeCSUATSupport: true,
    regxchangeCSPRODSupport: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    wallStreetDocsPostRequest: true,
  },
  'john.heath': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    addRules: true,
    editTemplates: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    editTargetSystem: true,
    regxchangeCSUATSupport: true,
    regxchangeCSPRODSupport: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'khrystyna.andronova': {
    management: true,
    approvals: true,
    testManager: true,
    editTargetSystem: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'gughan.santhanam': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'vedulla.jagdish' : {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'somashekhar.siddappa': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'viviana.rico': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'ben.pugh': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'sven.gilsdorf': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'stuart.goss': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    editTargetSystem: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'ralf.koellges': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    editTargetSystem: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  }, 'richard.gilson': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'ian.pemberton': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'ottavio.minasi': {
    workspaces: true,
    approvals: true,
    testManager: true,
    editTemplates: true,
    editSupportedStatus: true,
    addRules: true,
    editStaticData: true,
    rebaselineTests: true,
    management: true,
    editMappings: true,
    createConfig: true,
    canApproveRule: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'anne.laurila': {
    workspaces: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'rodney.harrison': {
    management: true,
    approvals: true,
    workspaces: true,
    editStaticData: true,
    editTargetSystem: true,
    addRules: true,
    rebaselineTests: true,
    editTemplates: true,
    editSupportedStatus: true,
    canRollbackCommit: true,
    editRuleSets: true,
    editMappings: true,
    createConfig: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    canApproveRule: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    restartSmartDx: true,
    testManager: true,
  },
  'edward.osbaldestin': {
    workspaces: true,
    editStaticData: true,
    editTargetSystem: true,
    addRules: true,
    rebaselineTests: true,
    editTemplates: true,
    canRollbackCommit: true,
    editRuleSets: true,
    editMappings: true,
    createConfig: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    canApproveRule: true
  },
  'david.ogundana': {
    management: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    stagingApprovals: true,
    uatApprovals: true,
    canApproveRule: true,
    createConfig: true,
    editMappings: true,
    editSupportedStatus: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'jasmine.hender': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'maitri.banerjee': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'divya.choudhry': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'nidhi.kapoor': {
    editTargetSystem: true,
    editStaticData: true,
    editTemplates: true,
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    editSupportedStatus: true,
    addRules: true,
    restartSmartDx: true,
    rebaselineTests: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    deleteRule: true,
    canApproveRule: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'rebecca.de.freitas': {
    workspaces: true,
    editStaticData: true,
    addRules: true,
    rebaselineTests: true,
    editTemplates: true,
    canRollbackCommit: true,
    editRuleSets: true,
    editMappings: true,
    createConfig: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'rishabh.mitra': {
    rebaselineTests: true,
    workspaces: true,
    editTemplates: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'rowan.boardman': {
    rebaselineTests: true,
    workspaces: true,
    editTemplates: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'jacob.greenberg': {
    rebaselineTests: true,
    workspaces: true,
    editTemplates: true,
    editStaticData: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    canApproveRule: true
  },
  'harry.gent': {
    rebaselineTests: true,
    workspaces: true,
    editTemplates: true,
    editStaticData: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true
  },
  'linda.feis': {
    workspaces: true,
    editStaticData: true,
    editTemplates: true,
    rebaselineTests: true,
    canRollbackCommit: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'benjamin.munkhart': {
    rebaselineTests: true,
    workspaces: true,
    addRules: true,
    editTemplates: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'kerstin.branaschky': {
    addRules: true,
    adminMenu: true,
    canRollbackCommit: true,
    configMenu: true,
    dashboardMenu: true,
    editRuleSets: true,
    editStaticData: true,
    editTemplates: true,
    generateMenu: true,
    inspectMenu: true,
    management: true,
    manualDraftingMenu: true,
    playgroundMenu: true,
    publishMenu: true,
    rebaselineTests: true,
    stagingApprovals: true,
    testManager: true,
    testsMenu: true,
    toolsMenu: true,
    uatApprovals: true,
    workspaces: true,
  },
  'laszlo.hathazy': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'fred.wigg': {
    management: true,
    approvals: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'alan.oliveria': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true,
  },
  'derek.sozou': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'gitanjali': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
  },
  'karol.piotrowski': {
    stagingApprovals: true,
    uatApprovals: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    createConfig: true,
    editMappings: true,
    editTemplates: true,
    editTargetSystem: true,
    addRules: true,
    editStaticData: true,
    editSupportedStatus: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    restartSmartDx: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'gorton.brown': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true,
  },
  'joe.humphreys': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true,
  },
  'amrita.budati': {
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    createConfig: true,
    editMappings: true,
    editTemplates: true,
    addRules: true,
    editStaticData: true,
    editSupportedStatus: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'aida.bala': {
    deleteRule: true,
    management: true,
    rebaselineTests: true,
    restartSmartDx: true,
    testManager: true,
    workspaces: true,
    stagingApprovals: true,
    uatApprovals: true,
    canApproveRule: true,
    createConfig: true,
    editMappings: true,
    editTemplates: true,
    addRules: true,
    canRollbackCommit: true,
    regxchangeCSUATSupport: true,
    editStaticData: true,
    editSupportedStatus: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true
  },
  'saumil.shah': {
    deleteRule: true,
    management: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    stagingApprovals: true,
    uatApprovals: true,
    canApproveRule: true,
    createConfig: true,
    editMappings: true,
    regxchangeCSUATSupport: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'julia.bauer': {
    management: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    stagingApprovals: true,
    uatApprovals: true,
    canApproveRule: true,
    createConfig: true,
    editMappings: true,
    editSupportedStatus: true,
    regxchangeCSUATSupport: true,
    canRollbackCommit: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'xuemei.jing': {
    editTemplates: true,
    editMappings: true,
    editStaticData: true,
    management: true,
    rebaselineTests: true,
    stagingApprovals: true,
    testManager: true,
    uatApprovals: true,
    workspaces: true,
    canRollbackCommit: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'christian.hofmann': {
    editTemplates: true,
    editMappings: true,
    editStaticData: true,
    management: true,
    rebaselineTests: true,
    stagingApprovals: true,
    testManager: true,
    uatApprovals: true,
    workspaces: true,
    canRollbackCommit: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'emily.tomlinson': {
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    addRules: true,
    deleteRule: true,
    management: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    stagingApprovals: true,
    createConfig: true,
    editMappings: true,
    editStaticData: true,
    editTemplates: true,
    canRollbackCommit: true,
    editSupportedStatus: true,
    editRuleSets: true,
    approvals: true,
    readonly: true,
    playgroundMenu: true,
    configMenu: true,
    manualDraftingMenu: true,
  },
  'duncan.richardson': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    regxchangeCSUATSupport: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    canApproveRule: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true

  },
  'daniel.wilczyk': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    regxchangeCSUATSupport: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true
  },
  'william.dovell': {
    addRules: true,
    deleteRule: true,
    rebaselineTests: true,
    testManager: true,
    workspaces: true,
    createConfig: true,
    editMappings: true,
    editStaticData: true,
    editTemplates: true,
    editSupportedStatus: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'artur.patricio': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'cameron.long': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    restartSmartDx: true,
    adminMenu: true,
  },
  'gabriele.bertone': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'giulia.baldini': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    addRules: true,
    editRuleSets: true
  },
  'kim.hunter': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'luke.dallyn': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'nicola.adkin': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'rishi.shah': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    editStaticData: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'yi.zhang': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true
  },
  'robert.ducan': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true
  },
  'royston.yinkore': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true
  },
  'andre.nogueira': {
    management: true,
    workspaces: true,
    testManager: true,
    developer: true,
    addRules: true,
    editTemplates: true,
    editSupportedStatus: true,
    restartSmartDx: true,
    rebaselineTests: true,
    deleteRule: true,
    editStaticData: true,
    editMappings: true,
    createConfig: true,
    canSaveNegativeTests: true,
    editTargetSystem: true,
    editRuleSets: true,
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
    preprocessorLogic: true,
    wallStreetDocsPostRequest: true
  },
  'selina.daniels': {
    playgroundMenu: true,
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    publishMenu: true,
    testsMenu: true,
    toolsMenu: true,
    manualDraftingMenu: true,
    adminMenu: true,
  },
  'stefan.bojinovic': { // CS CH employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'matthew.chui': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'kylie.poon': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'owen.lee': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'ansonkaching.chan': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'kristalokyiu.chow': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'alex.hung': { // CS APAC employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'halil.yeniavci': { // CS CH employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
    adminMenu: true,
    configMenu: true,
    editRuleSets: true,
    workspaces: true,
    addRules: true
  },
  'garrett.cassar': { // CS employee
    dashboardMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'nikhil.pawar': { // External
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'ievgenii.kamenets': { // External
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'vikrant.vankudre': { // External
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'shruti-b.kapoor': { // External
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'eva.ferreira': { // External
    dashboardMenu: true,
    addRules: true,
    editRuleSets: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'henrik.schoch': { // External
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'gerard.tang': {
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'peter.douglas': {
    dashboardMenu: true,
    configMenu: true,
    inspectMenu: true,
    generateMenu: true,
    testsMenu: true,
    toolsMenu: true,
  },
  'enrico.fabiano': { // Internal
    dashboardMenu: true,
    //configMenu: true,
    //inspectMenu: true,
    //generateMenu: true,
    //testsMenu: true,
    toolsMenu: true,
  },
  'assessments-pentest@strikegraph.com': {
    // management: true,
    // approvals: true,
    // workspaces: true,
    // testManager: true,
    // developer: true,
    // addRules: true,
    // editTemplates: true,
    // editSupportedStatus: true,
    // restartSmartDx: true,
    // rebaselineTests: true,
    // deleteRule: true,
    // editStaticData: true,
    // editMappings: true,
    // createConfig: true,
    // canSaveNegativeTests: true,
    // editTargetSystem: true,
    // editRuleSets: true,
    // playgroundMenu: true,
    // dashboardMenu: true,
    // configMenu: true,
    // inspectMenu: true,
    generateMenu: true,
    // publishMenu: true,
    // testsMenu: true,
    // toolsMenu: true,
    // manualDraftingMenu: true,
    // adminMenu: true,
  },
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private developerSubject = new BehaviorSubject<boolean>(false)
  developer = this.developerSubject.asObservable()

  private canRollbackCommitSubject = new BehaviorSubject<boolean>(false)
  canRollbackCommit = this.canRollbackCommitSubject.asObservable()

  private approverSubject = new BehaviorSubject<boolean>(false)
  approver = this.approverSubject.asObservable()

  private stagingApproverSubject = new BehaviorSubject<boolean>(false)
  stagingApprover = this.stagingApproverSubject.asObservable()

  private uatApproverSubject = new BehaviorSubject<boolean>(false)
  uatApprover = this.uatApproverSubject.asObservable()

  private workspacesSubject = new BehaviorSubject<boolean>(false)
  workspaces = this.workspacesSubject.asObservable()

  private managementSubject = new BehaviorSubject<boolean>(false)
  management = this.managementSubject.asObservable()

  private testManagerSubject = new BehaviorSubject<boolean>(false)
  testManager = this.testManagerSubject.asObservable()

  private addRulesSubject = new BehaviorSubject<boolean>(false)
  addRules = this.addRulesSubject.asObservable()

  private editRuleSetsSubject = new BehaviorSubject<boolean>(false)
  editRuleSets = this.editRuleSetsSubject.asObservable()

  private editTemplatesSubject = new BehaviorSubject<boolean>(false)
  editTemplates = this.editTemplatesSubject.asObservable()

  private editTargetSystemSubject = new BehaviorSubject<boolean>(false)
  editTargetSystem = this.editTargetSystemSubject.asObservable()

  private editSupportedStatusSubject = new BehaviorSubject<boolean>(false)
  editSupportedStatus = this.editSupportedStatusSubject.asObservable()

  private restartSmartDxSubject = new BehaviorSubject<boolean>(false)
  restartSmartDx = this.restartSmartDxSubject.asObservable()

  private rebaselineTestsSubject = new BehaviorSubject<boolean>(false)
  rebaselineTests = this.rebaselineTestsSubject.asObservable()

  private editStaticDataSubject = new BehaviorSubject<boolean>(false)
  editStaticData = this.editStaticDataSubject.asObservable()

  private editMappingsSubject = new BehaviorSubject<boolean>(false)
  editMappings = this.editMappingsSubject.asObservable()

  private createConfigSubject = new BehaviorSubject<boolean>(false)
  createConfig = this.createConfigSubject.asObservable()

  private deleteRuleSubject = new BehaviorSubject<boolean>(false)
  deleteRule = this.deleteRuleSubject.asObservable()

  private canSaveNegativeTestsSubject = new BehaviorSubject<boolean>(false)
  canSaveNegativeTests = this.canSaveNegativeTestsSubject.asObservable()

  private canApproveRuleSubject = new BehaviorSubject<boolean>(false)
  canApproveRule = this.canApproveRuleSubject.asObservable()

  private readonlySubject = new BehaviorSubject<boolean>(false)
  readonly = this.readonlySubject.asObservable()

  private regxchangeCSPRODSupportSubject = new BehaviorSubject<boolean>(false)
  regxchangeCSPRODSupport = this.regxchangeCSPRODSupportSubject.asObservable()

  private regxchangeCSUATSupportSubject = new BehaviorSubject<boolean>(false)
  regxchangeCSUATSupport = this.regxchangeCSUATSupportSubject.asObservable()

  // Storm left menu subjects
  private playgroundMenuSubject = new BehaviorSubject<boolean>(false)
  canViewPlayground = this.playgroundMenuSubject.asObservable()

  private dashboardMenuSubject = new BehaviorSubject<boolean>(false)
  canViewDashboard = this.dashboardMenuSubject.asObservable()

  private configMenuSubject = new BehaviorSubject<boolean>(false)
  canViewConfig = this.configMenuSubject.asObservable()

  private inspectMenuSubject = new BehaviorSubject<boolean>(false)
  canViewInspect = this.inspectMenuSubject.asObservable()

  private generateMenuSubject = new BehaviorSubject<boolean>(false)
  canViewGenerate = this.generateMenuSubject.asObservable()

  private publishMenuSubject = new BehaviorSubject<boolean>(false)
  canViewPublish = this.publishMenuSubject.asObservable()

  private testsMenuSubject = new BehaviorSubject<boolean>(false)
  canViewTests = this.testsMenuSubject.asObservable()

  private toolsMenuSubject = new BehaviorSubject<boolean>(false)
  canViewTools = this.toolsMenuSubject.asObservable()

  private manualDraftingMenuSubject = new BehaviorSubject<boolean>(false)
  canViewManualDrafting = this.manualDraftingMenuSubject.asObservable()

  private adminMenuSubject = new BehaviorSubject<boolean>(false)
  canViewAdmin = this.adminMenuSubject.asObservable()

  private preprocessorLogicSubject = new BehaviorSubject<boolean>(false)
  canViewPreprocessorLogic = this.preprocessorLogicSubject.asObservable()

  private wallStreetDocsPostRequestSubject = new BehaviorSubject<boolean>(false)
  canViewWallStreetDocsPostRequest = this.wallStreetDocsPostRequestSubject.asObservable()

  isReady = new BehaviorSubject<boolean>(false)

  constructor(userNameService: UserNameService, settingsService: SettingsService, apiService: ApiService) {
    console.log('Checking permissions')
    combineLatest([
      userNameService.userName,
      settingsService.settings()
    ]).pipe(
      switchMap(([username, settings]) => {
        if (!username) {
          return of({ loggedIn: false, permissions: [] as string[] })
        }

        let apiUrlOverride = (settings as Settings).permissionsEndpoint

        if (apiUrlOverride && username === 'royston.yinkore') {
          console.log('Using dynamic permissions from user-management-api')
          if (!apiUrlOverride.startsWith("http")) {
            apiUrlOverride = `https://${apiUrlOverride}`
          }

          return apiService.get<string[]>('', { apiUrlOverride, headers: {} }).pipe(
            map(permissions => ({ loggedIn: true, permissions })),
            catchError(error => {
              console.error('API error occurred:', error)
              return of({ loggedIn: true, permissions: ['readonly'] })
            })
          )
        }

        console.log('Using static permissions from local')
        return userPermissions[username]
          ? of({ loggedIn: true, permissions: Object.keys(userPermissions[username]) })
          : of({ loggedIn: true, permissions: ['readonly'] })
      })
    ).subscribe(
      ({ loggedIn, permissions }) => {

        if (!loggedIn) {
          console.log('Did not find permissions')
          this.removePermissions()
          return
        }

        const permissionsToAssign = permissions.reduce((acc, permission) => ({ ...acc, [permission]: true }), {} as UserPermission)

        console.log('assigning permissions', permissionsToAssign)
        this.assignPermissions(permissionsToAssign)
        this.isReady.next(true)
      })
  }

  private removePermissions() {
    this.developerSubject.next(false)
    this.approverSubject.next(false)
    this.stagingApproverSubject.next(false)
    this.uatApproverSubject.next(false)
    this.workspacesSubject.next(false)
    this.managementSubject.next(false)
    this.testManagerSubject.next(false)
    this.addRulesSubject.next(false)
    this.editMappingsSubject.next(false)
    this.editTargetSystemSubject.next(false)
    this.editTemplatesSubject.next(false)
    this.editSupportedStatusSubject.next(false)
    this.restartSmartDxSubject.next(false)
    this.rebaselineTestsSubject.next(false)
    this.editStaticDataSubject.next(false)
    this.createConfigSubject.next(false)
    this.deleteRuleSubject.next(false)
    this.canSaveNegativeTestsSubject.next(false)
    this.canApproveRuleSubject.next(false)
    this.readonlySubject.next(true)
    this.regxchangeCSPRODSupportSubject.next(false)
    this.regxchangeCSUATSupportSubject.next(false)
    this.playgroundMenuSubject.next(false)
    this.dashboardMenuSubject.next(false)
    this.configMenuSubject.next(false)
    this.inspectMenuSubject.next(false)
    this.generateMenuSubject.next(false)
    this.publishMenuSubject.next(false)
    this.testsMenuSubject.next(false)
    this.toolsMenuSubject.next(false)
    this.manualDraftingMenuSubject.next(false)
    this.adminMenuSubject.next(false)
    this.preprocessorLogicSubject.next(false)
  }

  private assignPermissions(permissions: UserPermission) {
    this.developerSubject.next(permissions.developer || false)
    this.canRollbackCommitSubject.next(permissions.canRollbackCommit || false)
    this.managementSubject.next(permissions.readonly || permissions.management || false)
    this.approverSubject.next(permissions.readonly || permissions.approvals || false)
    this.stagingApproverSubject.next(permissions.readonly || permissions.approvals || permissions.stagingApprovals || false)
    this.uatApproverSubject.next(permissions.readonly || permissions.approvals || permissions.uatApprovals || false)
    this.workspacesSubject.next(permissions.readonly || permissions.workspaces || false)
    this.testManagerSubject.next(permissions.readonly || permissions.testManager || false)
    this.addRulesSubject.next(permissions.readonly || permissions.addRules || false)
    this.editRuleSetsSubject.next(permissions.editRuleSets || false)
    this.editTemplatesSubject.next(permissions.readonly || permissions.editTemplates || false)
    this.editTargetSystemSubject.next(permissions.readonly || permissions.editTargetSystem || false)
    this.editMappingsSubject.next(permissions.readonly || permissions.editMappings || false)
    this.editSupportedStatusSubject.next(permissions.readonly || permissions.editSupportedStatus || false)
    this.restartSmartDxSubject.next(permissions.readonly || permissions.restartSmartDx || false)
    this.rebaselineTestsSubject.next(permissions.readonly || permissions.rebaselineTests || false)
    this.editStaticDataSubject.next(permissions.readonly || permissions.editStaticData || false)
    this.createConfigSubject.next(permissions.readonly || permissions.createConfig || false)
    this.deleteRuleSubject.next(permissions.readonly || permissions.deleteRule || false)
    this.canSaveNegativeTestsSubject.next(permissions.readonly || permissions.canSaveNegativeTests || false)
    this.canApproveRuleSubject.next(permissions.readonly || permissions.canApproveRule || false)
    this.playgroundMenuSubject.next(permissions.playgroundMenu || false)
    this.dashboardMenuSubject.next(permissions.dashboardMenu || false)
    this.configMenuSubject.next(permissions.configMenu || false)
    this.inspectMenuSubject.next(permissions.inspectMenu || false)
    this.generateMenuSubject.next(permissions.generateMenu || false)
    this.publishMenuSubject.next(permissions.publishMenu || false)
    this.testsMenuSubject.next(permissions.testsMenu || false)
    this.toolsMenuSubject.next(permissions.toolsMenu || false)
    this.manualDraftingMenuSubject.next(permissions.manualDraftingMenu || false)
    this.adminMenuSubject.next(permissions.adminMenu || false)
    this.readonlySubject.next(permissions.readonly || false)
    this.preprocessorLogicSubject.next(permissions.preprocessorLogic || false)
    this.regxchangeCSPRODSupportSubject.next(permissions.readonly || permissions.regxchangeCSPRODSupport || false)
    this.regxchangeCSUATSupportSubject.next(permissions.readonly || permissions.regxchangeCSUATSupport || false)
    this.wallStreetDocsPostRequestSubject.next(permissions.readonly || permissions.wallStreetDocsPostRequest || false)
  }

  isDeveloper() {
    return this.developerSubject.getValue()
  }
}
